import css from './Social.module.scss'
import React from 'react'

const Social: React.FC = () => {
  return (
    <div className={css.social}>
      {/*<div>*/}
      {/*  <a href="mailto:mino.togna@gmail.com">mino.togna@gmail.com</a>*/}
      {/*</div>*/}
      <div className={css.socialIcons}>
        <a href="https://wa.me/+393291591193" rel="noreferrer" target="_blank">
          <img alt="Whatsapp" src="/img/whatsapp.png" />
        </a>
        <a href="https://www.instagram.com/drususminor/" rel="noreferrer" target="_blank">
          <img alt="Instragram" src="/img/instragram.png" />
        </a>
        <a href="https://www.facebook.com/mino.togna/" rel="noreferrer" target="_blank">
          <img alt="Facebook" src="/img/facebook.png" />
        </a>
      </div>
    </div>
  )
}

export default Social
