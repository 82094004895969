import css from './About.module.scss'
import React from 'react'

const About: React.FC = () => {
  return (
    <div className={css.about}>
      <img alt="MinoTogna" className={css.aboutImg} src="/img/about.jpg" />

      <div className={css.aboutDesc}>
        <h2>About</h2>
        <div>Born in Italy.</div>
        <div>Living under the sun. Sometimes not.</div>
        <div>Studied photography at IED Rome in 2010.</div>
        <div>Currently counting trees and taking pictures.</div>
      </div>
    </div>
  )
}

export default About
