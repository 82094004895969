import css from './Nav.module.scss'
import React from 'react'
import { NavLink } from 'react-router-dom'

import classNames from 'classnames'

// import NavLink from 'client/components/Layout/Header/Nav/NavLink'

// import ProjectName from '@/client/components/ProjectName'
// import { useProjects } from '@/client/store/projects'

type Props = {
  show: boolean
}

const Nav: React.FC<Props> = (props) => {
  const { show } = props
  // const projects = useProjects()

  return (
    <div className={classNames(css.nav, { [css.show]: show })}>
      <nav>
        <ul>
          {/*{projects.map((project) => (*/}
          {/*  <NavLink key={project.uuid} href={`/projects/${project.name}`}>*/}
          {/*    <ProjectName name={project.name} />*/}
          {/*  </NavLink>*/}
          {/*))}*/}

          {/*<hr />*/}
          <NavLink className={css.navLink} to="/about">
            About
          </NavLink>
        </ul>
      </nav>
    </div>
  )
}

export default Nav
