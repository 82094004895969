import { AxiosRequestConfig, AxiosResponse } from 'axios'

// import { Clients } from 'meta/client'
import { axiosInstance } from 'utils/http/axiosInstance'
// import { AxiosResponse, BackendResponse, ResponseError } from 'utils/http/response'

const responseBody = <Data>(response: AxiosResponse<Data>) => {
  const { data } = response
  // if (data.error) {
  //   throw new ResponseError(data.error, data.status, data.exceptions)
  // }
  return data
}

// const init = () => {
//   const authToken = Clients.getAuthToken()
//   const baseUrl = Clients.getBaseUrl()
//
//   axiosInstance.defaults.headers.Authorization = `Bearer ${authToken}`
//   axiosInstance.defaults.baseURL = baseUrl
// }

export const HttpRequest = {
  // HeadersConfig,
  // init,
  // delete: <ResponseData = Record<never, never>, ResponseMetadata = Record<never, never>>(
  //   url: string,
  //   config?: AxiosRequestConfig
  // ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
  //   axiosInstance.delete<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>>(url, config).then(responseBody),
  get: <ResponseData>(url: string, config?: AxiosRequestConfig): Promise<ResponseData> =>
    axiosInstance.get<ResponseData>(url, config).then(responseBody),
  // patch: <ResponseData = Record<never, never>, Body = Record<never, never>, ResponseMetadata = Record<never, never>>(
  //   url: string,
  //   body: Body
  // ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
  //   axiosInstance
  //     .patch<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>, Body>(url, body)
  //     .then(responseBody),
  // post: <ResponseData = Record<never, never>, Body = Record<never, never>, ResponseMetadata = Record<never, never>>(
  //   url: string,
  //   body: Body,
  //   config?: AxiosRequestConfig
  // ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
  //   axiosInstance
  //     .post<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>, Body>(url, body, config)
  //     .then(responseBody),
  // put: <ResponseData = Record<never, never>, Body = Record<never, never>, ResponseMetadata = Record<never, never>>(
  //   url: string,
  //   body: Body
  // ): Promise<BackendResponse<ResponseData, ResponseMetadata>> =>
  //   axiosInstance.put<ResponseData, AxiosResponse<ResponseData, ResponseMetadata>, Body>(url, body).then(responseBody),
}
