import css from './Header.module.scss'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import classNames from 'classnames'

import Nav from 'client/components/Layout/Header/Nav'

const Header: React.FC = () => {
  const [showNav, setShowNav] = useState<boolean>(false)
  const location = useLocation()

  useEffect(() => {
    setShowNav(false)
  }, [location.pathname])

  return (
    <header className={css.header}>
      <div className={css.headerHomeLink}>
        <Link to="/">Mino Togna.</Link>
      </div>
      <strong style={{ color: `hsla(36, 72%, 70%, 1)` }}>BE RIGHT BACK</strong>
      <button
        className={classNames(css.headerMenuToggle, { [css.headerMenuToggle__open]: showNav })}
        onClick={() => setShowNav((prevState) => !prevState)}
        type="button"
      >
        <div />
      </button>

      <Nav show={showNav} />
    </header>
  )
}

export default Header
