import css from './Page.module.scss'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Footer from 'client/components/Layout/Footer'
import Header from 'client/components/Layout/Header'
import Map from 'client/components/Map'

const Page: React.FC = () => {
  return (
    <>
      <div className={css.page}>
        <Map />
        <Header />
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default Page
