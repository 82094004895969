import 'mapbox-gl/dist/mapbox-gl.css'
import css from 'client/components/Map/Map.module.scss'
import React, { useEffect, useState } from 'react'
import { Fog, Map as ReactMap, MapProps } from 'react-map-gl'

import mapboxgl from 'mapbox-gl'

import { ApiEndpoints } from 'meta/api'

import { HttpRequest } from 'utils/http'

const fog: Fog = {
  // 'color': 'rgba(32,32,129,0.1)',
  'color': 'rgba(255,255,255,0.8)',
  'high-color': '#202081',
  // 'high-color': 'rgba(32,32,129,0.5)',
  'horizon-blend': 0.05,
  'range': [-10, 20],
  'space-color': '#141516',
  'star-intensity': 0.4,
}

const initialViewState: MapProps['initialViewState'] = {
  latitude: 46.499307690696384,
  longitude: 26.31536500064732,
  zoom: 0.5,
}

const Map: React.FC = () => {
  const [initialized, setInitialized] = useState<boolean>(false)

  useEffect(() => {
    HttpRequest.get<{ token: string }>(ApiEndpoints.Map.token()).then((resp) => {
      const { token } = resp
      if (token) {
        mapboxgl.accessToken = token
        setInitialized(true)
      }
    })
  }, [])

  return (
    <div className={css.mapContainer}>
      {initialized && (
        <ReactMap
          // mapStyle="mapbox://styles/mapbox/streets-v9"
          // style={{ width: 600, height: 400 }}
          attributionControl={false}
          fog={fog}
          initialViewState={initialViewState}
          mapLib={mapboxgl}
          // mapStyle="mapbox://styles/minotogna/cltlgglvx01rv01pi698vgdm8"
          mapStyle="mapbox://styles/minotogna/clzf9zcii00cq01qwelrb9pnn"
        />
      )}
    </div>
  )
}

export default Map
