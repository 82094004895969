import axios from 'axios'
import axiosRetry from 'axios-retry'

// import { HeadersConfig } from 'utils/http/headersConfig'
// import { AxiosResponse, BackendResponse } from 'utils/http/response'

// import i18n from '../../i18n'

const axiosInstance = axios.create({
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    // 'Accept-Language': i18n.language,
    // 'Tsms-S2S-Token': Env.ticketOtpToken,
  },

  // TODO: verify status codes
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
  // validateStatus: (status) => {
  //   return status < 300
  //   // return status >= 200 && ![401, 503, 504].includes(status)
  // },
})

// let abortController = new AbortController()

axiosRetry(axiosInstance, {
  retries: 3,
  // retryCondition: ({ response }) => {
  //   // Using config.validateStatus but can be customized based on status codes
  //   return Boolean(response && (response.status < 200 || response.status === 504))
  // },
  retryDelay: (_) => 500,
})

// axiosInstance.interceptors.request.use(
//   async (config) => {
//     HeadersConfig.getInstance().prepareRequest({ config })
//
//     // if (config.url && (config.url.includes('login') || config.url.includes('signup'))) {
//     //   abortController = new AbortController()
//     // }
//
//     return config
//   },
//   (error) => {
//     return Promise.reject(error)
//   }
// )

// axiosInstance.interceptors.response.use(
//   async (response: AxiosResponse<unknown>) => {
//     // if (Manifests.isManifest(response.data.metadata)) {
//     //   ManifestController.verify({ manifest: response.data.metadata as Manifest })
//     // }
//     return response
//   },
//   (error: AxiosError<BackendResponse<unknown>>) => {
//     // TODO: verify later
//     // if (error?.response?.status === 401) {
//     //   abortController.abort()
//     //   AppEvents.emit(AppEventNames.storageClear)
//     // }
//
//     return Promise.reject({ ...error, message: error?.response?.data?.error })
//   }
// )

export { axiosInstance }
// TODO: verify if abortController is needed
// export { abortController, axiosInstance }
