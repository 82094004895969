import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { useRoutes } from './hooks/useRoutes'

const Router: React.FC = () => {
  const routes = useRoutes()

  return <RouterProvider router={createBrowserRouter(routes)} />
}

export default Router
