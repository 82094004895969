const joinParts = (...strings: Array<string>): string => `/api/${strings.join('/')}`

export const ApiEndpoints = {
  // Admin: {
  //   Projects: {
  //     projects: (): string => joinParts('admin', 'projects'),
  //     project: (projectUuid: string): string => joinParts('admin', 'projects', projectUuid),
  //     // story
  //     stories: (projectUuid: string): string => joinParts('admin', 'projects', projectUuid, 'stories'),
  //     story: (projectUuid: string, storyUuid: string): string =>
  //       joinParts('admin', 'projects', projectUuid, 'stories', storyUuid),
  //     // photo
  //     photos: (projectUuid: string, storyUuid: string): string =>
  //       joinParts('admin', 'projects', projectUuid, 'stories', storyUuid, 'photos'),
  //   },
  // },
  // Projects: {
  //   getMany: (): string => joinParts('projects'),
  // },
  Map: {
    token: (): string => joinParts('map', 'token'),
  },
}
