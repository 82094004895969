import css from './InnerPage.module.scss'
import React from 'react'
import { Outlet } from 'react-router-dom'

const InnerPage: React.FC = () => {
  return (
    <div className={css.innerPage}>
      <Outlet />
    </div>
  )
}

export default InnerPage
