import React, { useMemo } from 'react'
import { createRoutesFromElements, Route } from 'react-router-dom'

import InnerPage from 'client/components/Layout/InnerPage'
import Page from 'client/components/Layout/Page'
import About from 'client/Pages/About'
// import Landing from 'client/Pages/Landing'

export const useRoutes = () => {
  return useMemo(() => {
    const children = (
      <Route element={<Page />} path="/">
        {/*<Route element={<Landing />} index />*/}
        <Route element={<InnerPage />}>
          <Route element={<About />} path="about" />
        </Route>
      </Route>
    )

    return createRoutesFromElements(children)
  }, [])
}
