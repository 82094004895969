import css from './Footer.module.scss'
import React from 'react'

import Social from 'client/components/Layout/Footer/Social'

const Footer: React.FC = () => {
  return (
    <footer className={css.footer}>
      <Social />
      <div>{`© Copyright Mino Togna ${new Date().getFullYear()}`}</div>
    </footer>
  )
}

export default Footer
